import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { BLOCKS } from "@contentful/rich-text-types";
import Layout from "../../components/layout";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Top10Posts from "../../components/top10Posts";
import style from "styled-components";
import { mainColor } from "../../shared/layout.module.css";
import { Disqus } from "gatsby-plugin-disqus";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import "../../shared/blog.css";

const StyledImg = style(GatsbyImage)`
  display: block;
  margin: 0 auto;

  picture {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const BlogPost = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.href : "";
  const { contentfulBlogPosts } = data;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImageData, description } = node.data.target;
        if (!gatsbyImageData) {
          // asset is not an image
          return null;
        }
        return <StyledImg image={gatsbyImageData} alt={description} />;
      },
    },
  };

  const mainImageSrc =
    contentfulBlogPosts.mainImage.gatsbyImageData.images.sources[0].srcSet
      .split(",")[0]
      .split("?")[0];

  const disqusOptions = {
    url,
    identifier: url,
    title: contentfulBlogPosts.title,
  };

  return (
    <Layout
      pageTitle={contentfulBlogPosts.title}
      description={contentfulBlogPosts.description}
      ogImage={mainImageSrc}
      keywords={contentfulBlogPosts.keywords}
    >
      <p className="text-secondary">{contentfulBlogPosts.date}</p>
      <p className="text-secondary">Author: {contentfulBlogPosts.author}</p>
      <StyledImg
        image={contentfulBlogPosts.mainImage.gatsbyImageData}
        alt={contentfulBlogPosts.mainImage.description}
      />
      <hr />

      {renderRichText(contentfulBlogPosts.body, options)}

      <hr />

      <h5 className={mainColor}>Share</h5>
      <LinkedinShareButton
        className="p-2"
        url={url}
        title={contentfulBlogPosts.title}
        summary={contentfulBlogPosts.description}
        source={url}
      >
        <LinkedinIcon size={32} round={true} />
      </LinkedinShareButton>
      <FacebookShareButton
        className="p-2"
        url={url}
        quote={contentfulBlogPosts.description}
      >
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      <TwitterShareButton
        className="p-2"
        url={url}
        title={contentfulBlogPosts.title}
        hashtags={contentfulBlogPosts.keywords
          .split(",")
          .map((k) => k.trim().replaceAll(" ", "_"))}
      >
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      <Disqus config={disqusOptions} />
      <Top10Posts />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    contentfulBlogPosts(id: { eq: $id }) {
      contentful_id
      date(formatString: "MMMM DD, YYYY")
      title
      author
      body {
        raw
        references {
          contentful_id
          __typename
          description
          gatsbyImageData(cornerRadius: 10)
        }
      }
      mainImage {
        description
        gatsbyImageData(cornerRadius: 10)
      }
      description
      keywords
    }
  }
`;

export default BlogPost;
